<template>
  <div class="notification-table">
    <v-simple-table>
      <thead>
        <tr class="notification-table__header">
          <th>Method</th>
          <th>Event</th>
          <th>Event Date</th>
          <th>Notification Sent Date</th>
        </tr>
      </thead>
      <tbody class="notification-table__content">
        <template v-if="notifications.length > 0">
          <tr v-for="(notification, i) in notifications" :key="i">
            <td>
              {{ notification.methodName }}
            </td>
            <td>
              {{ notification.eventName }}
            </td>
            <td>
              <div :title="getDateWithDayNameAndTime(notification.eventDate)">
                {{ getDate(notification.eventDate) }}
              </div>
            </td>
            <td>
              <div
                v-if="notification.notificationSentDate"
                :title="getDateWithDayNameAndTime(notification.notificationSentDate)"
              >
                {{ getDate(notification.notificationSentDate) }}
              </div>
              <div v-else>
                -
              </div>
            </td>
          </tr>
        </template>
        <template v-else>
          <tr>
            <td colspan="5">
              <div>
                Currently you do not have any notifications. Make sure that notifications are configured in the plugin configuration page.
              </div>
            </td>
          </tr>
        </template>
      </tbody>
    </v-simple-table>
  </div>
</template>

<script>
import datesMixin from "@/utils/datesMixin.js";

export default {
  name: "NotificationTable",
  mixins: [datesMixin],
  props: {
    notifications: {
      default: () => [],
      type: Array,
    },
  },
};
</script>

<style lang="scss" scoped>
.notification-table {
  // .v-data-table {
  &__header {
    th {
      font-size: 13px !important;
      color: #241c15 !important;
      font-family: "Open Sans", sans-serif;
      font-weight: 600;
    }
  }
  &__notification-header {
    font-family: "Open Sans", sans-serif;
    font-size: 15px;
    font-weight: 600;
    line-height: normal;
    color: #241c15;
  }
  &__notification-subheader {
    font-family: "Open Sans", sans-serif;
    font-size: 12px;
    line-height: normal;
    color: #66788e;
  }
  .v-menu-button:focus {
    background: #2b84eb !important;
    opacity: 1;
    .v-btn__content {
      color: white;
    }
  }
  &__content {
    tr td {
      font-size: 13px !important;
      color: #241c15 !important;
      font-family: "Open Sans", sans-serif !important;
      * {
        font-size: 13px;
        color: #241c15;
        font-family: "Open Sans", sans-serif;
      }
    }
  }

  thead {
    tr > th {
      border-bottom: solid 1px #dee7ee !important;
    }
  }
  tbody {
    // table border colors
    tr > td {
      border-bottom: solid 1px #dee7ee;
    }
    tr:hover {
      background: inherit !important;
    }
  }
  // }

  .panel-button {
    font-family: "Open Sans", sans-serif;
    font-size: 13px !important;
    color: #66788e;
    text-transform: none;
    background: #ecf1f5 !important;
  }
  .panel-button[aria-expanded="true"] {
    color: white;
    background: #2b84eb !important;
    opacity: 1 !important;
  }
}
</style>
