<template>
  <v-container class="mt-3 px-sm-10 px-3 segments-accordion elevation-0" fluid>
    <PageHeader
      header-text="Notifications"
    />

    <v-row>
      <v-col
        sm="12"
        md="6"
      >
        <CustomDropdown
          v-model="event"
          :items="events"
          header="Event"
          placeholder="Show all Events"
          item-text="name"
          item-value="key"
        />
      </v-col>
      <v-col
        sm="12"
        md="6"
      >
        <CustomDropdown
          v-model="method"
          :items="methods"
          header="Notification Method"
          placeholder="Show all Methods"
          item-text="name"
          item-value="key"
        />
      </v-col>
      <v-col cols="12" align="right">
        <v-btn
          class="custom-button custom-button--blue"
          height="34px"
          width="75px"
          depressed
          @click="onSearch"
        >
          Search
        </v-btn>
      </v-col>
    </v-row>
    <v-row class="d-flex align-center justify-space-between pb-2">
      <v-col
        v-if="total > pageSize"
        class="d-flex flex-nowrap align-center justify-end flex-grow-1 pl-2"
      >
        <PaginationButtons
          :current-page-number="pageNumber"
          :current-page-size="pageSize"
          :count-total="total"
          :page-sizes="[25, 50, 100]"
          @next-page="getNextPage"
          @prev-page="getPrevPage"
          @change-page-size="changePageSize"
        />
      </v-col>
    </v-row>

    <NotificationTable
      :notifications="notifications"
    />
  </v-container>
</template>

<script>
import PageHeader from "@/sharedComponents/PageHeader";
import PaginationButtons from "@/sharedComponents/pagination/PaginationButtons";
import NotificationTable from "@/views/Plugins/Notification/NotificationTable";
import { hasError } from "@/mixins/has_error";
import CustomDropdown from "../../../sharedComponents/CustomDropdown";

export default {
  name: "PluginNotificationView",
  metaInfo: {
    title: 'View Notifications'
  },
  components: {
    CustomDropdown,
    NotificationTable,
    PaginationButtons,
    PageHeader,
  },
  mixins: [hasError],
  data() {
    return {
      dialog: false,
      notifications: [],
      total: 0,
      pageNumber: 1,
      pageSize: 25,
      events: [],
      methods: [],
      event: null,
      method: null,
    };
  },
  async created() {
    if (this.$route?.query?.event) {
      this.event = this.$route.query.event;
    }
    if (this.$route?.query?.method) {
      this.method = this.$route.query.method;
    }

    await this.getNotificationValues();
    await this.onSearch();
  },
  methods: {
    async getNotificationValues() {
      const eventResp = await this.$rest.plugins.notification.getEvents();
      if (eventResp.data) {
        this.events = eventResp.data.data;
        this.events.unshift({
          key: null,
          name: "Show all Events",
        });
      }
      const methodResp = await this.$rest.plugins.notification.getMethods();
      if (methodResp.data) {
        this.methods = methodResp.data.data;
        this.methods.unshift({
          key: null,
          name: "Show all Notification Methods",
        });
      }
    },
    async onSearch() {
      const searchCriteria = {};
      if (this.event) {
        searchCriteria.event = this.event;
      }
      if (this.method) {
        searchCriteria.method = this.method;
      }

      const resp = await this.$rest.plugins.notification.getCollection({
        limit: this.pageSize,
        page: this.pageNumber,
        sort: ['createdAt:desc'],
        isArchived: false,
        ...searchCriteria,
      });
      this.notifications = resp.data.items.map(notification => ({
        ...notification,
        eventName: this.getEventName(notification.event),
        methodName: this.getMethodName(notification.notificationMethod),
      }));
      this.total = resp.data.totalCount;
    },
    getEventName(eventKey) {
      return this.events.find(event => event.key === eventKey)?.name ?? eventKey;
    },
    getMethodName(methodKey) {
      return this.methods.find(method => method.key === methodKey)?.name ?? methodKey;
    },
    async changePageSize(size) {
      this.pageSize = size;
      this.pageNumber = 1;
      await this.onSearch();
    },
    async getNextPage() {
      this.pageNumber += 1;
      await this.onSearch();
    },
    async getPrevPage() {
      this.pageNumber -= 1;
      await this.onSearch();
    },
  }
};
</script>
